import logo from '../images/logo.png';
import { springestHref } from '../content/springest-link';

const Header = () => (
  <header className="App-header">
    <div className="App-header__top">
      <div className="Block-content App-header__content">
        <a href="/" className="App-logo">
          <img src={logo} alt="logo" />
        </a>
        <a className="top-header-link" target="_blank" rel="noopener noreferrer" href={springestHref}>
          Bekijk op Springest
        </a>
      </div>
    </div>
    <div>
      <div className="App-header__bottom">
        <div className="hero-background-img" />
        <div className="Block-content App-header__content">
          <h1 className="Hero-heading">
            Prijs Onderhandelen
            <br />
            Anchoring Tactiek
          </h1>
          <h3 className="Hero-subheading">Leren door te doen E-Learning</h3>
          <div className="hero-buttons-container">
            <a className="CTA-link" href="#video">
              Bekijk de Voorbeeldvideo
            </a>
            <a className="CTA-link CTA-link--secondary" target='_blank' rel="noopener noreferrer" href={springestHref}>
              Ontdek meer op Springest
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
