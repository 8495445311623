import logo from '../images/logo.png';
import ObfuscatedEmailAddress from './obfuscated-email-address';

const Footer = () => {
  return (
    <footer className="Footer">
      <ul className="Footer-content">
        <li>
          <ObfuscatedEmailAddress />
        </li>
        <li>
          <img className='Footer-logo-img' src={logo} alt="logo" />
        </li>
        <li>
          © {new Date().getFullYear()} <a href="/">hansverheijke.com</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
