import './App.css';
import LearnWithHans from './components/learn-with-hans';
import ThreeUsps from './components/three-usps';
import ProgramOverview from './components/program-overview';
import KeyLearningMethod from './components/key-learning-method';
import Header from './components/header';
import Footer from './components/footer';
import VideoTrailer from './components/video-trailer';
import PromoFreeCoaching from './components/promo-free-coaching';
import BusinessPerformanceAcademy from './components/business-performance-academy';
import Signup from './components/signup';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <LearnWithHans />
        <VideoTrailer />
        <ThreeUsps />
        <ProgramOverview />
        <KeyLearningMethod />
        <BusinessPerformanceAcademy />
        <PromoFreeCoaching />
      </main>
      <Footer />
    </div>
  );
}

export default App;
