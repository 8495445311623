import Vimeo from '@u-wave/react-vimeo';
import { springestHref } from '../content/springest-link';

const VideoTrailer = () => {
  return (
    <div className="Block Block-video" id="video">
      <div className="Block-content">
        <div className="Video-container">
          <Vimeo
            video="873889734"
            responsive
            showByline={false}
            showPortrait={false}
            showTitle={false}
            pip={false}
            onError={(error) => console.log('video error', error)}
          />
        </div>
        <a className="CTA-link" target='_blank' rel="noopener noreferrer" href={springestHref}>
          <span>Bestel nu</span>
        </a>
      </div>
    </div>
  );
};

export default VideoTrailer;
