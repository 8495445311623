import imgSrc from '../images/bpalogo.png';

const BusinessPerformanceAcademy = () => {
  return (
    <div className="Block Block-bpa">
      <h2 className="Block-content Block-heading">Business Performance Academy</h2>
      <div className="Block-content Block-content__left-media">
        <div className="Block-media">
          <img src={imgSrc} alt="Logo van Business Performance Academy" />
        </div>
        <div className="Block-text">
          <p>
            Business Performance Academy bevordert duurzaam leiderschap door middel
            van blended en experiential learning, waar actieve, feedback-ondersteunde
            deelname centraal staat.
          </p>
          <ul>
            <li>Opgericht in 2004 door Hans Verheijke in Heidelberg, Duitsland</li>
            <li>
              Samenwerking met Prof. Dr. Joachim Funke, Psychologisch Instituut,{' '}
              <a
                href="https://www.uni-heidelberg.de/"
                target="_blank"
                rel="noreferrer"
              >
                Universiteit Heidelberg
              </a>
            </li>
            <li>
              Partners in 26 landen, meer dan 100.000 internationale deelnemers
            </li>
          </ul>
          <a className="Link" href="https://www.bpacademy.eu/">
            Business Performance Academy
          </a>
        </div>
      </div>
    </div>
  );
};

export default BusinessPerformanceAcademy;
