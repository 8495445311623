import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyDTJC-7u9R5Ss6mnBlxpmqmh2TDeA1A50c',
  authDomain: 'hansverheijke-b1653.firebaseapp.com',
  databaseURL:
    'https://hansverheijke-b1653-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'hansverheijke-b1653',
  storageBucket: 'hansverheijke-b1653.appspot.com',
  messagingSenderId: '396563936112',
  appId: '1:396563936112:web:bcc91b467d0658939c95b5',
  // measurementId: 'G-1DQX7B2Q8N', // TODO see what this enables
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

export const FIRESTORE_PRE_REGISTRATION_PATH = 'pre-registration';

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
if (process.env.NODE_ENV === 'production') {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LchPb4oAAAAAJoREFqw088sHR4Wy206-A-tRo7a'),
    isTokenAutoRefreshEnabled: true,
  });
}
