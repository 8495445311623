import hans from '../images/hans.png';

const LearnWithHans = () => {
  return (
    <div className="Block Block-hans">
      <h2 className="Block-content Block-heading">Leren met {`Hans Verheijke`}</h2>
      <div className="Block-content Block-content__left-media">
        <div className="Block-media">
          <img src={hans} alt="Foto van Hans Verheijke" />
        </div>
        <div className="Block-text">
          <p>
            Hans heeft zich in zijn 35-jarige internationale ervaring als coach
            gespecialiseerd in het ontwikkelen van trainingen om mensen te motiveren
            zich verder te ontwikkelen.
          </p>
          <p>
            Na zijn carrière als directeur bij Krauthammer International en Wolters Kluwer Professional Training richtte hij in 2004 Business Performance Academy op.
          </p>
          <p>
            Hij coacht directies en sales management in leiderschap en onderhandelen.
            Zijn stijl is positief provocatief, veel oud deelnemers behoren tot de
            top van het bedrijfsleven.
          </p>
          <p>
            Geïnspireerd door zijn passie voor effectief leiderschap en
            onderhandelen, lanceerde Hans het Prijs Onderhandelen E-Learning
            programma om een breder publiek te bereiken en praktische vaardigheden te
            delen.
          </p>
        </div>
      </div>
    </div>
  );
};


export default LearnWithHans;