import ObfuscatedEmailAddress from './obfuscated-email-address';

const PromoFreeCoaching = () => {
  return (
    <div className="Block Block-free-coaching">
      <h2 className="Block-content Block-heading">
        Win een gratis personal coaching
      </h2>
      <div className="Block-content">
        <div className="Block-text">
          <p>
            Schrijf een praktijk verslag hoe jouw onderhandeling verliep en mail mij dit verslag. Een keer per maand kies ik de
            winnaar uit de ingezonden verslagen. Deze deelnemer krijgt een gratis
            personal coaching ter waarde van <strong>€&nbsp;175,00.</strong>
          </p>
          <ObfuscatedEmailAddress />
        </div>
      </div>
    </div>
  );
};

export default PromoFreeCoaching;
