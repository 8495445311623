import image1Src from '../images/study.png';
import image2Src from '../images/teach.png';
import image3Src from '../images/success.png';

const ThreeUsps = () => {
  return (
    <div className="Block Block-three-usps">
      <div className="Block-content Block-content__grid">
        <div className="Block-content__grid-item">
          <div className="Block-media">
            <img
              className="Block-image"
              src={image1Src}
              alt="Illustratie van een lerende persoon"
            />
          </div>
          <div className="Block-content">
            <h3>Wat leer je?</h3>
            <ul>
              <li>De “Anchoring-effect” tactiek</li>
              <li>De klant koning te maken</li>
              <li>Gezond zelfvertrouwen</li>
            </ul>
            <em>
              Negen wetenschappelijke onderzoekingen bewezen het “Anchoring-effect”.
            </em>
          </div>
        </div>

        <div className="Block-content__grid-item">
          <div className="Block-media">
            <img
              className="Block-image"
              src={image2Src}
              alt="Illustratie van een docent"
            />
          </div>
          <div className="Block-content">
            <h3>
              k<span className="emphasized-heading-letter">E</span>y-learning methode
            </h3>
            <p>Leren door te doen maakt je authentiek</p>
            <em>
              “De k<strong>E</strong>y-learning methode prikkelt je motivatie om
              kennis om te zetten in gedrag”
            </em>
          </div>
        </div>

        <div className="Block-content__grid-item">
          <div className="Block-media">
            <img
              className="Block-image"
              src={image3Src}
              alt="Illustratie van een succesvolle businessman"
            />
          </div>
          <div className="Block-content">
            <h3>Bewezen resultaten</h3>
            <p>Meer dan 10.000 internationale deelnemers</p>
            <em>
              “Ik heb geleerd dat prijs onderhandelen waarde van het product of
              dienst opbouwen betekent”
            </em>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeUsps;
