import Vimeo from '@u-wave/react-vimeo';
import { springestHref } from '../content/springest-link';

const ProgramOverview = () => {
  return (
    <div className="Block Block-program-overview">
      <h2 className="Block-content Block-heading">
        Programma
        <wbr />
        overzicht
      </h2>
      <div className="Block-content Block-content__left-lg-media">
        <div className="Block-media">
          <div className="Video-container">
            <Vimeo
              video="873889933"
              responsive
              showByline={false}
              showPortrait={false}
              showTitle={false}
              pip={false}
              onError={(error) => console.log('video error', error)}
            />
          </div>
        </div>
        <div className="Block-text">
          <p>
            De E-learning training in Prijs Onderhandelen heeft een duur van 4 uur en
            is opgebouwd uit verschillende modules die je de fijne kneepjes van het
            vak leren.
          </p>
          <ol>
            <li>Introductie</li>
            <li>Onderhandelen met visie</li>
            <li>Het Anchoring-effect</li>
            <li>Mind-set en gedrag</li>
            <li>De kracht van de vraag</li>
            <li>Omgaan met bezwaren</li>
            <li>Strategie en tactiek</li>
            <li>Synopsis</li>
            <li>Mijn fitness plan</li>
            <li>Deel je ervaringen</li>
          </ol>
          <div className="Block-text-cta">
            <a className="CTA-link" target='_blank' rel="noopener noreferrer" href={springestHref}>
              Bestel nu
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramOverview;
