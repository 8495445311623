import imgSrc from '../images/learnthinkapply.png';
import { springestHref } from '../content/springest-link';

const KeyLearningMethod = () => {
  return (
    <div className="Block Block-key-learning-method">
      <h2 className="Block-content Block-heading">
        Waarom de kEy-learning methode
      </h2>
      <div className="Block-content Block-content__right-media">
        <div className="Block-media">
          <img src={imgSrc} alt="Illustratie van learn-think-apply" />
        </div>
        <div className="Block-text">
          <p>
            De Business Performance Academy heeft de resultaten van onderhandeling
            trainingen onderzocht. Alleen die opleidingen waar men zijn sterkten en
            verbeterpunten leerde bleken de beste resultaten te brengen. Met andere
            woorden: jezelf goed kennen, jouw talenten zijn de basis voor
            ontwikkeling.
          </p>
          <h4>Fasen kEy-learning methode</h4>
          <ol>
            <li>Gespreksvoorbeeld waarvan jij de fouten moet ontdekken</li>
            <li>Jouw variant om de gespreksfase te verbeteren</li>
            <li>Optimale variant aan de hand van concrete voorbeeldzinnen</li>
            <li>Analyse en samenvatting door kernpunten</li>
            <li>
              Jouw <strong>P</strong>ersoonlijk <strong>O</strong>ntwikkeling{' '}
              <strong>P</strong>unt <strong>(POP)</strong>
            </li>
          </ol>
          <div className="Block-text-cta">
            <a className="CTA-link" target='_blank' rel="noopener noreferrer" href={springestHref}>
              Bekijk op Springest
            </a>
          </div>
        </div>
        </div>
    </div>
  );
};

export default KeyLearningMethod;
